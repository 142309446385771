export * from './multipleCoupon.js'
import { plus } from '@shein/common-function'

export function getFilterSort(language = {}) {
  return [
    {
      label: language.SHEIN_KEY_PWA_15647,
      label_en: language.SHEIN_KEY_PWA_15647,
      value: 0,
    },
    {
      label: language.SHEIN_KEY_PWA_17370,
      label_en: language.SHEIN_KEY_PWA_17370,
      value: 12,
    },
    {
      label: language.SHEIN_KEY_PWA_15557,
      label_en: language.SHEIN_KEY_PWA_15557,
      value: 11
    },
    {
      label: language.SHEIN_KEY_PWA_15645,
      label_en: language.SHEIN_KEY_PWA_15645,
      value: 9,
    },
    {
      label: language.SHEIN_KEY_PWA_15646,
      label_en: language.SHEIN_KEY_PWA_15646,
      value: 10,
    },
  ]
}

export function fotmatCategories(cateList = []) {
  if (!Array.isArray(cateList) || !cateList.length) return []
  let list = []
  cateList.forEach(({ cat_id, cat_name, children } = {}) => {
    if (cat_id && cat_name) {
      list.push({
        label: cat_name,
        value: cat_id,
        children: fotmatCategories(children),
      })
    }
  })
  return list
}

/**
 * 根据券档位获取券折扣信息
 * @param {object} rule 档位
 * @return {String} 返回折扣文案, 结果为: 带币种金额 或 打折百分比
 */
export function getCouponDiscount(rule) {
  if (!rule) return null
  return rule.value_amount ? rule.value_amount.amountWithSymbol : Number(rule.value) + '%'
}

/**
 * 根据券档位计算进度
 * 进度公式：(1 - 差额 / (当前门槛 - 上一档门槛)) * 100
 * @param {object} preRule 上一档位
 * @param {object} curRule 凑单档位
 * @return {Number} 
 */
export function getCouponProgress(curRule, preRule) {
  if (!curRule) return 0
  const preRuleMin = preRule?.min || 0
  return (1 - curRule.need_price?.amount / (Number(curRule.min) - Number(preRuleMin))) * 100
}

/**
 * 格式化coupon
 * @param {object} coupon 优惠券信息
 * @return {Object} _data 里的字段为客户端自处理数据
 */
export function formatCoupon(coupon) {
  if (!coupon?.coupon_code) return {}
  const { coupon_rule_list, next_range_id, status } = coupon
  const activeRule = coupon_rule_list?.find((rule) => rule.coupon_range_id == next_range_id)
  const activeRuleIndex = coupon_rule_list?.findIndex((r) => r.coupon_range_id == next_range_id)

  let is_satisfied, preRule
  const _status = Number(status)

  if (_status === 1) {
    // 未达到门槛
    is_satisfied = 0
    preRule = null
  } else if (_status === 2) {
    // 多档券部分满足
    is_satisfied = 2
    preRule = coupon_rule_list[activeRuleIndex - 1]
  } else if (_status === 3) {
    // 3-已全部满足
    is_satisfied = 1
    preRule = coupon_rule_list[coupon_rule_list.length - 1]
  }

  return {
    ...coupon,
    _data: {
      activeRule, // 当前正在凑单的档位
      activeRuleIndex, // 当前正在凑当档位索引
      preRule, // 凑单档位的上一个档位
      is_satisfied, // 0:全部未满足; 1:全部满足; 2:部分满足
    },
  }
}

/**
 * 计算活动每个进度
 * @param {object} rule 规则
 * @param {object} preRule 上一档规则
 * @param {Number} ruleIndex 规则索引
 * @param {object} rangeInfo 活动档位信息
 * @return {Number} 进度值
 */
export function calcPromotionProcess(rule, preRule, ruleIndex, rangeInfo) {
  const { range, next, diff = {} } = rangeInfo || {}
  if (next == 0) { // 该活动所有档位凑满
    return 100
  }
  if (ruleIndex == range) {
    const curV = rule?.value_amount?.amount || rule?.value || 0
    const preV = preRule?.value_amount?.amount || preRule?.value || 0
    const diffV = diff?.value_amount?.amount || diff?.value || 0
    const x = curV - diffV // 已满足值（金额 or 件数） = 当前档位门槛 - 差值
    // (已满足值 - 上一档位门槛) / (当前档位门槛 - 上一档位门槛) * 100
    return (x - preV) / (curV - preV) * 100
  }

  return range > ruleIndex ? 100 : 0
}

export function getFreeShippingChange(newValue, oldValue) {
  const {
    activityId: newActivityId,
    activityType: newActivityType,
    shippingMethodName: newShippingMethodName,
    nowLevel: newNowLevel,
  } = newValue
  const {
    activityId: oldActivityId,
    activityType: oldActivityType,
    shippingMethodName: oldShippingMethodName,
    nowLevel: oldNowLevel,
  } = oldValue
  const isChange = newActivityId !== oldActivityId || newActivityType !== oldActivityType || newShippingMethodName !== oldShippingMethodName
  const isFreightStageChange = newNowLevel != oldNowLevel
  return {
    isChange,
    isFreightStageChange,
  }
}

/**
 * 获取加车商品的页面来源（add_source）
 */
export function getAddSourceByType(type, saInfo) {
  let _type = ''
  switch (type) {
    case 'coupon':
      _type = 'coupon' // 优惠券
      break
    case 'freeShipping':
      _type = 'shipping' // 免邮活动
      break
    default: // 其他值都归为促销活动
      _type = 'promotion' // 促销活动
      break
  }
  return `cartadd\`${_type}\`${saInfo?.state || saInfo?.activity_from || 'cartadd'}`
}

/**
 * 传入函数只执行一次
 */
export function runOnce(fn) {
  let executed = false
  
  return function() {
    if (executed) return // 执行过了直接返回
    
    fn?.()
    executed = true
  }
}

/**
 * 获取活动进度变化状态
 * @param {Array} cur 当前
 * @param {Array} pre 上一次
 * @return {Object}
 */
export function getPromotionProgressStatus(cur, pre) {
  const curRange = cur?.[0]?.range
  const preRange = pre?.[0]?.range

  if (curRange !== preRange) {
    return {
      isUp: curRange > preRange,
      isDown: curRange < preRange,
      isChange: true,
      isRangeUp: curRange > preRange,
      isRangeDown: curRange < preRange,
      isRangeChange: true,
    }
  }

  const curProgress = plus(...cur?.map(v => v?.progressRate)) || 0
  const preProgress = plus(...pre?.map(v => v?.progressRate)) || 0
  return {
    isUp: curProgress > preProgress,
    isDown: curProgress < preProgress,
    isChange: curProgress !== preProgress,
    isRangeUp: false,
    isRangeDown: false,
    isRangeChange: false,
  }
}
