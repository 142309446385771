<template>
  <div 
    :class="[
      'progress-bar j-progress-bar',
      size === 'mini' && 'mini',
    ]"
    :style="styleConfig"
  >
    <div
      v-if="isSingle === true"
      key="single"
      class="progress-bar-bg"
      :class="{
        'bg-single-rule': singleProgress > 0,
        'is-animation': animation,
        'is-transparent': transparent,
        'progress-bar-bg__free-shipping': isFreeShipping,
        'progress-bar-bg__free-shipping_no-transition': isFreeShipping && !isTransition,
        'progress-bar-bg__free-shipping_no-border': isFreeShipping && singleProgress == 0,
      }"
      :style="{
        width: `${singleProgress}%`,
      }"
    >
    </div>
    <template v-else-if="isSingle === false">
      <div
        key="multiple"
        class="progress-bar-bg"
        :class="{
          'bg-multiple-rule': true,
          'is-animation': animation,
          'is-transparent': transparent,
        }"
        :style="{
          width: `${multipleProgress}%`,
        }"
      >
      </div>
      <template v-if="progressData && !isSingle">
        <CircleIcon
          v-for="(item, index) in progressData"
          :key="index"
          class="ico"
          :style="{
            [GB_cssRight ? 'left' : 'right']: `${100/progressData.length * (progressData.length - index - 1)}%`
          }"
          :size="circleIconSize"
          :complete="isComplete(item)"
          :opacity="isOpacity(item)"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { EventProgressUpdate } from '../../utils/event.js'
const { GB_cssRight } = gbCommonInfo

const circleIconSize = '10'
const circleIconSize4Mini = '5'

export default {
  components: {
    CircleIcon: () => import(/* webpackChunkName: "add_on_item" */'public/src/pages/common/addOnItem/comps/base/CircleIcon.vue'),
  },
  props: {
    /**
     * 获取数据的多种方式：
     * eventbus => 通过 EventProgressUpdate 获取数据
     * props => 通过 props 获取数据
     */
    mode: {
      type: String,
      default: 'eventbus',
    },
    /**
     * 进度数据
     * 注: mode 为 props 才生效
     */
    data: {
      type: Array,
      default: () => ([]),
    },
    size: {
      type: String,
      default: '',
    },
    animation: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    styleConfig: {
      type: Object,
      default: () => ({})
    },
    isFreeShipping: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      progressData: null,
      isSingle: null, // 初始化时，single/multiple 进度条都不渲染
      singleProgress: '',
      multipleProgress: '',
      isTransition: true, // 进度条是否需要过渡动画
      GB_cssRight,
    }
  },
  computed: {
    circleIconSize() {
      if (this.size === 'mini') {
        return circleIconSize4Mini
      }
      return circleIconSize
    },
  },
  watch: {
    data: {
      handler(v) {
        if (this.mode === 'props') {
          this.setProgressData(v)
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.mode === 'eventbus') {
      EventProgressUpdate.subscribe({
        callback: (...args) => {
          this.setProgressData(...args)
        }
      })
    }
  },
  beforeDestroy() {
    if (this.mode === 'eventbus') {
      EventProgressUpdate.remove('')
    }
  },
  methods: {
    isComplete(item) {
      return item.activeRuleIndex == -1 || item.activeRuleIndex > item.ruleIndex
    },
    isOpacity(item) {
      if (item.activeRuleIndex == -1) {
        return item.ruleIndex !== this.progressData.length - 1
      }
      return item.ruleIndex >= item.activeRuleIndex || item.activeRuleIndex - item.ruleIndex > 1
    },
    async setProgressData(data) {
      if (!data) return

      this.progressData = data

      const isSingle = data.length == 1 || data[0]?.activeRuleIndex == 0
      const isChange = data[0]?.isChange || false
      const isLoop = data[0]?.isLoop || false
      const isEveryFull = data[0]?.isEveryFull || false
      const isFullMaxDiscount = data[0]?.isFullMaxDiscount || false
      const range = data[0]?.range || 0
      // 首次超过第一档执行超档动画
      // 第一次调用时 this.isSingle 为 null，不执行超档动画
      if (this.isSingle === true && !isSingle) {
        this.singleProgress = '100'
        await this.sleep(300)
        this.isSingle = isSingle
        await this.sleep(30)
        this.multipleProgress = this.getMultipleProgress(data)
        return
      }
      if (isSingle) {
        this.isSingle = isSingle
        let newProcess = this.getSingleProgress(data)
        if ((this.isFreeShipping && isChange)) {
          this.singleProgress = 100
          await this.sleep(320)
          this.isTransition = false
          this.singleProgress = 0
          await this.sleep(500)
          this.isTransition = true
          this.singleProgress = this.getSingleProgress(data)
          return
        }
        if(isLoop && !isFullMaxDiscount) {
          if(newProcess == 0) {
            this.singleProgress = 100
            await this.sleep(320)
            return
          }
          this.singleProgress = 100
          await this.sleep(320)
          this.isTransition = false
          this.singleProgress = 0
          await this.sleep(500)
          this.isTransition = true
          this.singleProgress = newProcess
          return
        }
        if(isEveryFull && this.singleProgress == 100 && !isFullMaxDiscount) {
          this.isTransition = false
          this.singleProgress = 0
          await this.sleep(500)
          this.isTransition = true
          this.singleProgress = newProcess
          return
        }
        if(isEveryFull && this.singleProgress == 0 && range && !newProcess && !isFullMaxDiscount) { // match rules, reset process to 100
          this.isTransition = false
          this.singleProgress = 100
          await this.sleep(500)
          this.isTransition = true
          return
        }
        if(isEveryFull && range && !newProcess && !isFullMaxDiscount) { // match rules, reset process to 100
          this.isTransition = false
          this.singleProgress = 100
          await this.sleep(500)
          this.isTransition = true
          return
        }
        this.singleProgress = newProcess
      } else {
        this.isSingle = isSingle
        this.multipleProgress = this.getMultipleProgress(data)
      }
    },
    getSingleProgress(progressData) {
      const val = progressData[0]?.progressRate
      return val
    },
    getMultipleProgress(progressData) {
      return progressData?.reduce((acc, cur) => {
        return acc + (cur.progressRate / progressData.length)
      }, 0)
    },
    sleep(time) {
      return new Promise(r => setTimeout(r, time))
    }
  },
}
</script>

<style lang="less" scoped>
@BorderRadius: 12/75rem;
@height: 12/37.5rem;
@BorderRadius4Mini: 6/75rem;
@height4Mini: 6/37.5rem;

.progress-bar {
  position: relative;
  width: 100%;
  height: @height;
  background: #EDEDED;
  border-radius: @BorderRadius;
  overflow: hidden;
  box-shadow: var(--box-shadow, 0px 0px 2px 0px #CCC inset);

  &.mini {
    height: @height4Mini;
    border-radius: @BorderRadius4Mini;

    .progress-bar-bg {
      height: @height4Mini;
      border-radius: @BorderRadius4Mini;

      // stylelint-disable-next-line selector-max-specificity
      &.is-transparent::before {
        border-radius: @BorderRadius4Mini;
      }
    }

    .ico {
      top: .5/37.5rem;
    }
  }

  .progress-bar-bg {
    position: relative;
    box-sizing: border-box;
    height: @height;
    border-radius: @BorderRadius;
    background-size: 450/37.5rem;
    max-width: 100%;
    &.bg-single-rule {
      border: var(--inner-bar-border, 0.5px solid #FF5F1B);
      background-image: var(--inner-bar-bg, repeating-linear-gradient(
        115deg,
        #FF8F3F 0%,
        #FF8F3F 1%,
        #FF772B 1%,
        #FF772B 2%
      ));
    }
    &.bg-multiple-rule {
      border: var(--inner-bar-border, 0.5px solid #E6433E);
      background-image: var(--inner-bar-bg, repeating-linear-gradient(
        115deg,
        #FB5F56 0%,
        #FB5F56 1%,
        #E03C38 1%,
        #E03C38 2%
      ));
      /* rw:begin */
      background-image: var(--inner-bar-bg, repeating-linear-gradient(
        115deg,
        #F949B2 0%,
        #F949B2 1%,
        #F81E55 1%,
        #F81E55 2%
      ));
    }

    &.is-animation {
      animation: slider-ltr 16s linear infinite /* rtl: slider-rtl 16s linear infinite */;
      transition: width .3s linear;
    }
    &.is-transparent::before {
      content: '';
      border-radius: @BorderRadius;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to left, transparent, rgba(255, 255, 255, .2));
    }
    &.progress-bar-bg__free-shipping {
      position: relative;
      border: 0.5px solid #fff;
      background-image: linear-gradient(to right, #198055, #6ABD8C 38%) /* rw:linear-gradient(to right, #F949B2, #F81E55 38%) */;
      transition: width .3s linear;
      &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        background-image: repeating-linear-gradient(
          115deg,
          transparent 0%,
          transparent 1%,
          rgba(255, 255, 255, 0.3) 1%,
          rgba(255, 255, 255, 0.3) 2%
        );
        background-size: 450/37.5rem;
        animation: slider-ltr 16s linear infinite /* rtl: slider-rtl 16s linear infinite */;
      }
      &_no-transition {
        transition: none;
      }
      &_no-border {
        border: none;
      }
    }
  }

  .ico {
    position: absolute;
    // left: 0;
    top: 1/37.5rem;
  }
}

@keyframes slider-ltr {
  0% { background-position-x: 100%; }
}
@keyframes slider-rtl {
  0% { background-position-x: -100%; }
}
</style>
